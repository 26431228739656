.generate-qr-code-button {
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
  height: 83px;
  width: 100%;
  max-width: 250px;
  background: #6c95ff;
  color: white;
  text-transform: uppercase;
  font-family: Tahoma, Arial, sans-serif;
  letter-spacing: 0.5px;
  font-size: 22px;
  font-weight: 500;
  border-radius: 50px;
  border: none;
  padding: 0 10px;
}
