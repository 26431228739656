.App {
  text-align: center;
}

button {
  cursor: pointer;
}

input:not([type='color']):not([type='checkbox']) {
  padding-left: 10px !important;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 20px;
}
textarea {
  padding-left: 10px !important;
  font-family: Inter !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 29px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  border: 1px solid #ccc;
}

select {
  padding-right: 10px; /* Adjust as needed to make room for the custom arrow */
  background-image: url('./Assets/selector.png');
  background-size: 40px 40px; /* Set the width and height of the background image */
  background-repeat: no-repeat;
  background-position: right center;
  font-size: 10px; /* Set the font size for the non-selected text */
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;

  appearance: none;
}

/* Increase the font size of the selected text */
select::-ms-expand {
  font-size: 10px !important;
}
select {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
  padding-left: 20px;
}
ul,
ol {
  list-style: none;
}
@media (max-width: 600px) {
  .label {
    font-size: 14px;
  }
}
