.download-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  gap: 10px;
}

.download-buttons button {
  display: flex;
  align-items: center;
  height: 80px;
  width: 240px;
  background-color: #6c95ff;
  text-transform: uppercase;
  font-family: Tahoma, Arial, sans-serif;
  font-size: 21px;
  font-weight: 500;
  border-radius: 50px;
  border: none;
  padding: 0 5px;
}

.download-buttons svg {
  margin: 0 5px 0 7px;
  width: 46px;
  height: 36px;
}

.download-buttons button span {
  letter-spacing: 0;
  /*
  color: black is required to fix blue text color on Safari
  https://imgur.com/a/kceOcoq
  */
  color: black;
}

.download-buttons button[disabled] {
  background-color: #d9d9d9;
  color: white;
  cursor: default;
}

.download-buttons button[disabled] span {
  color: white;
}

@media (max-width: 1280px) {
  .download-buttons {
    justify-content: space-around;
  }
}

@media (max-width: 600px) {
  .download-buttons {
    flex-direction: column;
    gap: 24px;
  }
}
