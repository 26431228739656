.input-row-wifi {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
  margin-bottom: 20px;
}

.input-row-wifi:last-of-type {
  margin-bottom: 0;
}

.wifiInput {
  width: 100%;
  height: 50px;
  border-radius: 20px;
  color: black;
}
.red-border {
  border-color: red;
}

.wifiInput-main {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.check-input {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  border-radius: 20px;
  flex-direction: column;
}

.wifi-text {
  color: #00000099;
  display: flex;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 10px;
  text-align: left;
}

.check-input1 {
  border-radius: 20% !important;

  display: block !important;
  width: 30px;
  height: 40px;
}

@media (max-width: 1320px) and (min-width: 660px) {
  .input-row-wifi {
    gap: 20px;
    flex-direction: column;
  }
  .wifiInput-main {
    width: 100%;
  }
  .check-input {
    width: 100%;
  }
}

@media (max-width: 660px) {
  .input-row-wifi {
    gap: 20px;
    flex-direction: column;
  }
  .wifiInput-main {
    width: 100%;
  }
  .check-input {
    width: 100%;
  }

  .label {
    color: #00000099;
    display: flex;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 10px;
    text-align: left;
  }
  .wifi-text {
    color: #00000099;
    display: flex;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 10px;
    text-align: left;
  }
}

@media (max-width: 370px) {
  .wifiInput-main select {
    font-size: 18px;
  }
}

@media (max-width: 370px) {
  .wifi-text {
    justify-content: center;
    text-align: center; /* Required to avoid https://imgur.com/a/CmCMrfb */
  }

  .check-input {
    align-items: center;
  }
}

