label {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
}
.red-border {
  border-color: red;
}

.text-area {
  height: 393px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 24px;
  width: 100%;
  border-radius: 20px;
  display: block;
}
.beta {
  display: flex;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0500ff;
  margin-top: 20px;
}

.genCode {
  display: none;
}

@media (max-width: 992px) {
  .text-area {
    height: 250px;
  }
}

@media (max-width: 600px) {
  .text-area {
    height: 150px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 10px;
  }

  .genCode {
    display: block;
  }
}
@media (max-width: 600px) {
  label {
    color: #00000099;
    display: flex;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 10px;
    text-align: left;
  }
}

@media (max-width: 370px) {
  .text-editor label {
    justify-content: center;
  }
}
