.step1Main {
  display: flex;
  justify-content: space-between;
  /* align-items: baseline; */
  background: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
  gap: 50px;
}

.step1Main .form-group:last-of-type {
  margin-bottom: 0;
}

.step1Main .form-group textarea {
  display: block;
}

.pay-img {
  /* height: 66px; */
  width: 240px;
  border-radius: 50px;
}

.item {
  cursor: pointer;
}

.step1-main2 {
  width: 577px;
}

.step1-main1 {
  width: 70%;
}

.step1Main input {
  height: 50px;
}

.step1Main input,
.step1Main textarea {
  box-sizing: border-box;
}

.pick1 {
  width: 90px;
  height: 90px;
  border-radius: 20px;
}

.option-text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px;
  color: #00000099;
}

.gradiant-type-input::selection {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px;
}

.item-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  /* Adjust the max-width as needed */
  border-radius: 20px;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  border-bottom-left-radius: 20px !important;
}

.frame-main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}

.top-text-input {
  width: 94%;
  height: 66px;
  border-radius: 20px;
  border: 1px solid #000000;
}

.item-container > div {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50px !important;
  border-top-right-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  border-bottom-left-radius: 50px !important;

  /* Center vertically */
}

.item-img {
  width: 50px;
  height: 50px;
}

.item-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #00000099;
}

.qr-img svg path {
  fill: blue;
}

.Qr-img svg {
  display: block;
}

.plus-main {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px !important;
}

.plus-main2 {
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 24px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plus-text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
  margin-bottom: 10px;
  cursor: pointer;
}

.plus-img {
  cursor: pointer;

  width: 40px;
  height: 40px;
}

.custom-color-div {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.color-text {
  width: 278px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: white;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
}

/* Hide the default checkbox */
input[type='checkbox'] {
  display: none;
}

/* Style the label as a switch */
label[for='toggleSwitch'] {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  background-color: greenyellow;
  border-radius: 17px;
}

/* Style the "track" of the switch */
label[for='toggleSwitch']:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: greenyellow;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: 0.4s;
}

/* Move the "track" to the right when the checkbox is checked */
input[type='checkbox']:checked + label[for='toggleSwitch']:before {
  transform: translateX(26px);
}

.switch {
  display: flex;
  gap: 50px;
}

.body-color {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.color-picker img {
  display: none;
}

.color-picker {
  display: flex;
  gap: 50px;
  justify-content: flex-start;
  align-items: center;
}

/* Hide the default input box */
input[type='color'] {
  appearance: none;
  border: none;
  width: 100px;
  /* Adjust the width as needed */
  height: 100px;
  /* Adjust the height as needed */
  background: transparent;
}

/* Style the colored square */
input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 0;
}

.b1-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #000000;
}

.b11-text {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: #ffffff;
}

.d-img {
  width: 40px;
  height: 40px;
}

.url-text {
  color: #00000099;
  display: flex;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 29px;
  margin-bottom: 10px;
  text-align: left;
}

.url-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 20px;
}

.url-input1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 20px;
}

.div-file {
  display: flex;
  justify-content: center;
  align-items: center;
}

.url-input::placeholder {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.url-input1::placeholder {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.active {
  background-color: #bfbebe;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.Noactive {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.b-pattern {
  max-width: 90px;
  margin-right: 10px;
  /* Adjust the gap between images */
  box-sizing: border-box;
  /* Include padding and border in width */
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  /* border: 2px solid grey; */
  /* Additional image styles */
}

/* Optional: If you want the last image in the row to have no right margin */
.image-row:last-child .b-pattern {
  margin-right: 0;
}
.new-QR {
  padding-top: 60px;
  padding-bottom: 50px;
}

.b-p-text {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
}

.p-gr {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.dev {
  display: none;
}

.ps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 52px;
}

.custom-file-input {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 90px;
  height: 90px;
  box-sizing: border-box;
  white-space: nowrap;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 20px;
  cursor: pointer;
  background: white;
  overflow: hidden;
  border-radius: 20px;
}

.img-div {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  height: 66px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background: #6c95ff;

  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 10px;
  border: none;
}

.custom-file-input1 {
  position: relative;
  display: inline-block;
}

.custom-button {
  background: #6c95ff;

  color: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 66px !important;
}

.custom-button:hover {
  background-color: #0056b3;
}

#fileInput {
  display: none;
}

.my-qrcode {
  width: 350px;
  height: 350px;
}

.qr-img {
  position: relative;
  width: 350px;
  height: 350px;
  margin: auto;
  /* Adjust the dimensions to match your QR code */
}

.div-border-style {
  width: 69%;
  margin: auto;
}

.overlay-image {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* Ensure the overlay image appears above the QR code */
  opacity: 0.7;
  /* Adjust the opacity as needed */
}

.red-border {
  border-color: red;
}

.u-div {
  display: flex;
  gap: 80px;
  justify-content: flex-start;
  align-items: center;
}

.custom-file-input input {
  display: none;
}

.url-input1 {
  display: none;
}

.p-background {
  background: #bfbebe;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px;
}

.genCode {
  display: none;
}

.p1-background {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 10px;
}

/* styles.css or styles.scss */
.underlined-text {
  text-decoration: underline !important;
  /* Add an underline to the text */
  /* You can also customize other styles, like color, if needed */
}

.div-img {
  white-space: pre;
}

.f-img {
  width: 100px !important;
  height: 100px !important;
}

.Qr-img {
  width: 450px;
  height: 450px;
}

.mobileView {
  display: none;
}

.selected {
  border: 5px solid black;
  border-radius: 25px;
}

#table-container {
  height: 100%;
}

.loading-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-wrapper span {
  flex-grow: 1;
}

@media (max-width: 1370px) {
  .plus-text {
    font-size: 20px;
  }

  .b-text {
    font-size: 16px;
  }

  .item-text {
    font-size: 16px;
  }
}

@media (max-width: 1280px) {
  .item-container > div {
    flex-basis: 200px;
  }
}

@media (max-width: 1280px) {
  .step1Main {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .item-container > div {
    flex-basis: calc(33% - 10px);
  }

  .div-border-style {
    width: 40%;
  }

  .step1-main2 {
    width: 100%;
  }

  .step1-main1 {
    width: 100%;
  }

  .ps {
    justify-content: space-around;
  }

  .ps {
    gap: 0;
  }
}

@media (max-width: 600px) {
  .mobileView {
    display: block !important;
  }

  .Qr-img {
    width: 300px;
    height: 300px;
  }
  .new-QR {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .div-border-style {
    width: 60%;
  }

  .selected {
    border-radius: 15px;
  }

  .new-div {
    display: none;
  }

  .url-input1 {
    display: block;
  }

  .url-input {
    display: none;
  }

  .genCode {
    display: block;
  }

  .u-div {
    gap: 30px;
  }

  .active {
    padding: 2px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .webView {
    display: none !important;
  }

  .item-img {
    width: 45px;
    height: 45px;
  }

  .plus-img {
    width: 20px;
    height: 20px;
  }

  .d-img {
    width: 20px;
    height: 20px;
  }

  .qr-img {
    width: 200px;
    height: 200px;
  }

  .b1-text {
    font-size: 12px;
  }

  .b11-text {
    font-size: 12px;
  }

  .ps {
    flex-direction: column;
    gap: 20px;
  }

  .color-picker {
    gap: unset;
    justify-content: space-between;
  }

  .color-text {
    height: 90px;
    width: 180px;
    box-sizing: border-box;
  }

  input[type='color'] {
    width: 66px;
    height: 66px;
  }

  .item-container > div {
    flex-basis: calc(45% - 10px);
    justify-content: flex-start;
  }

  .f-img {
    width: 66px;
    height: 66px;
  }

  .body-color {
    font-size: 20px;
  }

  .top-text {
    font-size: 20px;
  }

  .frame-main {
    gap: 7px;
  }

  .gradiant-typeptext {
    font-size: 16px;
  }

  .Gradient-text {
    font-size: 16px;
  }

  .img-div {
    font-size: 16px;
  }

  .b-p-text {
    font-size: 20px;
  }

  .b-p-text2 {
    font-size: 20px;
  }

  .plus-main,
  .plus-main2 {
    justify-content: space-between !important;
  }

  .plus-main2 {
    justify-content: space-between !important;
    padding-left: 10px;
  }

  .p-background {
    margin-top: 0px;
  }

  .item-text {
    margin-left: 0px;
  }
}

@media (max-width: 460px) {
  .custom-file-input {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    font-size: 11px;
    line-height: 16px;
  }

  .b-pattern {
    max-width: 50px;
    border-radius: 10px;
  }

  .pick1 {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }

  .color-text {
    height: 50px;
    width: 135px;
    border-radius: 10px;
  }
}

@media (max-width: 370px) {
  .item-container > div {
    flex-basis: 100%;
  }

  .child-item {
    margin: 0 auto;
  }

  .url-text {
    justify-content: center;
  }
}

@media (max-width: 360px) {
  .color-picker {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .color-text {
    width: 100%;
  }
}
