.back-button-wrapper {
  display: none;
}

@media (max-width: 600px) {
  .back-button-wrapper {
    display: block;
    position: absolute;
    left: 20px;
    top: 25px;
  }

  .back-button {
    background: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    transition-duration: 200ms;
    border: none;
  }

  .back-button:active {
    background: lightgrey;
  }

  .back-button svg {
    height: 100%;
  }
}
