.my-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label.v-card-block-title {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  gap: 10%;
}

.input-row:last-of-type {
  margin-bottom: 0;
}

.input-1 {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 50%;
  border-radius: 20px;
  height: 67px;
}
.input-2 {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  height: 67px;
  border-radius: 20px;
}
.generate-code {
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 86px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  background: #6c95ff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  color: white;
  border: none;
}
.empty-field {
  border: 1px solid red;
}

input::placeholder {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.beta-v {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 20px;
}

.input-1.first-input {
  margin-top: 0;
}

@media (max-width: 550px) {
  .input-1 {
    width: 100%;
    margin-top: 20px;
  }
  .input-2 {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .input-row {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    gap: 0px;
  }
}

@media (max-width: 370px) {
  label.v-card-block-title {
    text-align: center;
  }
}
