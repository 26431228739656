.form-group {
  margin-bottom: 20px;
}

.label {
  display: flex;
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
}

.textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
  height: 286px;
}
.input {
  width: 100%;
  height: 66px;

  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 20px;
}
.beta {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0500ff;
}

@media (max-width: 1440px) {
  .textarea {
    height: 200px;
  }
}
@media (max-width: 600px) {
  .label {
    color: #00000099;
    display: flex;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 10px;
    text-align: left;
  }
}

@media (max-width: 370px) {
  .label {
    justify-content: center;
    text-align: center; /* Required to avoid https://imgur.com/a/CmCMrfb */
  }
}
